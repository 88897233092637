import {
  BsFillChatTextFill,
  BsFillHeartFill,
  BsPeopleFill,
} from "react-icons/bs";
import { FiSettings } from "react-icons/fi";

import { RiDashboardFill } from "react-icons/ri";
import { MdRoomService, MdOutlineManageHistory } from "react-icons/md";
import { FaBox, FaHome, FaPlusSquare } from "react-icons/fa";

export const customerRoutes = [
  {
    id: 135294523,
    path: "/",
    name: "Back to Home",
    icon: <FaHome />,
  },
  {
    id: 623549,
    path: "/customer-dashboard",
    name: "Dashboard",
    icon: <RiDashboardFill />,
  },
  {
    id: 623542359,
    path: "/my-orders",
    name: "My Enquiries",
    icon: <FaBox />,
  },
  {
    id: 1,
    path: "/chat",
    name: "Messages",
    icon: <BsFillChatTextFill />,
  },
  // {
  //   id: 2,
  //   path: "/admin-customer-message",
  //   name: "message to admin",
  //   icon: <BsFillChatTextFill />,
  // },
  {
    id: 3,
    path: "/favourite",
    name: "Favourites",
    icon: <BsFillHeartFill />,
    exact: true,
  },
  // {
  //   id: 4,
  //   path: "/customer-profile",
  //   name: "My profile",
  //   icon: <IoMdContact />,
  //   exact: true,
  // },
  {
    id: 5,
    path: "/customer-settings",
    name: "Settings",
    icon: <FiSettings />,
    exact: true,
  },
];

export const venderRoutes = [
  {
    id: 135294523235,
    path: "/",
    name: "Back to Home",
    icon: <FaHome />,
  },
  {
    id: 6,
    path: "/vender-dashboard",
    name: "Dashboard",
    icon: <RiDashboardFill />,
  },
  {
    id: 7,
    path: "/create-service",
    name: "Create Service",
    icon: <FaPlusSquare />,
  },
  {
    id: 8,
    path: "/my-services",
    name: "My Services",
    icon: <MdRoomService />,
  },
  {
    id: 9,
    path: "/customer-request",
    name: "Customer Request",
    icon: <BsPeopleFill />,
  },
  {
    id: 10,
    path: "/chat",
    name: "Messages",
    icon: <BsFillChatTextFill />,
  },
  // {
  //   id: 11,
  //   path: "/vender-admin-message",
  //   name: "message to admin",
  //   icon: <BsFillChatTextFill />,
  // },
  {
    id: 12,
    path: "/manage-membership",
    name: "Manage Memberships",
    icon: <MdOutlineManageHistory />,
  },

  // {
  //   id: 13,
  //   path: "/vender-payments",
  //   name: "Manage Card",
  //   icon: <HiCurrencyDollar />,
  // },

  // {
  //   id: 14,
  //   path: "/vender-profile",
  //   name: "My profile",
  //   icon: <IoMdContact />,
  //   exact: true,
  // },
  {
    id: 15,
    path: "/vender-settings",
    name: "Settings",
    icon: <FiSettings />,
    exact: true,
  },
];
