import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCTUd-HpxHo8RZyJyZu7XSfEABXCRk9W44",
  authDomain: "occasion-base.firebaseapp.com",
  projectId: "occasion-base",
  storageBucket: "occasion-base.appspot.com",
  messagingSenderId: "111886386569",
  appId: "1:111886386569:web:6e1bbfe850f43fedd68d58",
  measurementId: "G-CXE6YKWJME",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const storage = getStorage();
export const db = getFirestore();
