import React, { useEffect, useMemo, useRef, useState } from "react";
import "./HeroSection.scss";

import Slider from "react-slick";

import Container from "../container/Container";

import { FiSearch } from "react-icons/fi";

import { slideImage1 } from "../../assests";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { objToStr } from "../../utils";
import AutoCompleteNew from "../AutoCompleteNew/AutoCompleteNew";
import styled from "@emotion/styled";
import { BACKEND_STATIC_URL } from "../../constants";
import { LandingPageHeroImage } from "../../assests";

const AutoCompleteNewStyled = styled(AutoCompleteNew)`
  width: 100%;
  border: 1px solid black;
  padding: 2.4rem 1.5rem;
  border-radius: 3rem;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  /* width: 20rem; */
  height: 3rem;
  flex-shrink: 0;
  outline: none;
`;

const HeroSection = () => {
  const navigate = useNavigate();
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  // const { selectedOccasion, setSelectedOccasion } = useContext(OccasionContext);
  const [occassion, setOccassion] = useState(null);
  const [cityValue, setCityValue] = useState();
  const { occasions: eventData } = useSelector((s) => s.occasionReducer);
  const { landingPageInfo } = useSelector((s) => s.publicReducer);
  var options = useMemo(
    () => ({
      types: ["(cities)"],
      componentRestrictions: { country: "uk" },
    }),
    []
  );

  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );
    autoCompleteRef.current.addListener("place_changed", async function () {
      const place = await autoCompleteRef.current.getPlace();
      console.log(place.name);
      setCityValue(place.formatted_address);
    });
  }, [options]);

  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    slickNext: true,
    autoplay: true,

    autoplaySpeed: 1700,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleSearch = () => {
    if (!occassion || !cityValue) {
      toast.error("Occasion and city is required");
    } else {
      navigate(
        `/search-results?${objToStr({
          occassion: occassion._id,
          city: cityValue,
        })}`
      );
    }
  };

  return (
    <>
      <Container className="container">
        <div
          className="hero"
          style={{
            backgroundImage: `url("${
              landingPageInfo?.heroImage
                ? `${BACKEND_STATIC_URL}${landingPageInfo?.heroImage}`
                : LandingPageHeroImage
            }")`,
          }}
        >
          <div className="hero-container">
            <div className="hero-container-content">
              <h1>
                Celebrating Life's
                <br />
                Special Moments
              </h1>
              <p>
                {landingPageInfo?.text ??
                  `Looking for the perfect suppliers to celebrate life's special moments? Look no further than OccasionBase's online directory of exceptional vendors`}
              </p>

              <div className="hero-container-content-form">
                <AutoCompleteNewStyled
                  placeholder="Choose Occasion"
                  options={eventData}
                  keyName="name"
                  onChange={setOccassion}
                  value={occassion}
                />
                {/* <Selector
                  selected={occassion?.name}
                  setSelected={(v) => {
                    setOccassion(v);
                  }}
                  data={eventData}
                  className="selector-rounded"
                  fullObj
                /> */}

                <input
                  ref={inputRef}
                  className="hero-container-content-input"
                  type="text"
                  placeholder="Enter City/Town"
                />

                <button
                  className="hero-container-content-form-button"
                  onClick={() => handleSearch()}
                >
                  <FiSearch />
                </button>
              </div>
            </div>
            <div className="hero-container-slider">
              {landingPageInfo.pictures?.length > 0 && (
                <Slider {...settings}>
                  {landingPageInfo.pictures?.map((obj, i) => {
                    const children = (
                      <img
                        src={`${BACKEND_STATIC_URL}${obj?.imgURL}`}
                        alt="sliderImage"
                      />
                    );

                    return obj?.LinkURL ? (
                      <Link key={i} to={obj?.LinkURL} className="slide-Image">
                        {children}
                      </Link>
                    ) : (
                      <div key={i} className="slide-Image">
                        {children}
                      </div>
                    );
                  })}
                </Slider>
              )}
              {!(landingPageInfo.pictures?.length > 0) && (
                <Slider {...settings}>
                  <div className="slide-Image">
                    <img src={slideImage1} alt="sliderImage" />
                  </div>
                  <div className="slide-Image">
                    <img src={slideImage1} alt="sliderImage" />
                  </div>
                  <div className="slide-Image">
                    <img src={slideImage1} alt="sliderImage" />
                  </div>
                  <div className="slide-Image">
                    <img src={slideImage1} alt="sliderImage" />
                  </div>
                  <div className="slide-Image">
                    <img src={slideImage1} alt="sliderImage" />
                  </div>
                  <div className="slide-Image">
                    <img src={slideImage1} alt="sliderImage" />
                  </div>
                </Slider>
              )}
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default HeroSection;
