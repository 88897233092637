import React, { useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { LoginLayout } from "../../../layout";
import { customersignup } from "../../../assests";
import { Button, FormInput } from "../../../components";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Puff } from "react-loader-spinner";
import {
  CustomerRegister,
  clearErrors,
  clearMessages,
} from "./../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import styled from "@emotion/styled";

const CustomerSignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { message, errors, loading } = useSelector(
    (state) => state.customerAuthReducer
  );

  const validation = Yup.object({
    fullName: Yup.string()
      .min(2, "Name must be 2 character")
      .max(25, "Must be 25 characters or less")
      .required("Name is required"),
    email: Yup.string().email("Email is invalid").required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password should be minimum 8 characters long"),
    phone: Yup.string()
      .matches(/^0/, "Phone number must start with zero")
      .matches(/^\d*$/, "Phone number must contain only numeric characters")
      .required("Contact number is required"),
  });
  useEffect(() => {
    if (errors?.length > 0) {
      toast.error(errors);
      dispatch(clearErrors());
    }
    if (message !== "") {
      toast.success(message);
      dispatch(clearMessages());
      setTimeout(() => navigate("/customersignin"), 2000);
    }
  }, [dispatch, errors, message, navigate]);

  return (
    <>
      <LoginLayout
        image={customersignup}
        heading="Create an account"
        bottom={true}
      >
        <Formik
          initialValues={{
            fullName: "",
            email: "",
            password: "",
            phone: "",
            isAgreed: false,
            addedToMailList: true,
          }}
          validateOnMount
          validationSchema={validation}
          onSubmit={(values, { resetForm }) => {
            const copy = { ...values };
            copy.email = copy.email.toLowerCase();
            delete copy.isAgreed;
            copy["role"] = "User";
            dispatch(CustomerRegister(copy));
            resetForm();
          }}
        >
          {(formik) => (
            <Form>
              <FormInput label="Full name" name="fullName" type="text" />
              <FormInput label="Enter your email" name="email" type="email" />
              <FormInput label="Phone number" name="phone" type="text" />
              <FormInput
                label="Create a password"
                name="password"
                type="password"
              />
              <div className="already-have-account m-2">
                <h3>Already have an account. </h3>
                <Link to="/customersignin">Login</Link>
              </div>
              {/* <Text className="m-1 center">
                By creating an account you agree to our terms and conditions.
              </Text> */}
              {/* <Button className="btn-lighter rounded center m-2">
                {loading ? (
                  <Puff
                    height="25"
                    width="30"
                    radius="6"
                    color="white"
                    ariaLabel="loading"
                    wrapperStyle
                    wrapperClass
                  />
                ) : (
                  "Submit"
                )}
              </Button> */}

              <BottomContainerStyled>
                <div className="group">
                  <MyCheckBox
                    label={
                      <>
                        I agree to the <Link to="/term-for-customer">T&Cs</Link>{" "}
                        and <Link to="/privacy-policy">Privacy policy</Link> of
                        Occasion Base.
                      </>
                    }
                    onChange={() =>
                      formik.setFieldValue("isAgreed", !formik.values.isAgreed)
                    }
                    checked={formik.values.isAgreed}
                    error={formik.touched.isAgreed && formik.errors.isAgreed}
                  />
                  <MyCheckBox
                    label={
                      <>
                        I would like to receive news, offers and promotions from
                        Occasion Base.
                      </>
                    }
                    onChange={() =>
                      formik.setFieldValue(
                        "addedToMailList",
                        !formik.values.addedToMailList
                      )
                    }
                    checked={formik.values.addedToMailList}
                    error={
                      formik.touched.addedToMailList &&
                      formik.errors.addedToMailList
                    }
                  />
                </div>
                <Button
                  className="btn-lighter rounded center "
                  disabled={!formik.dirty || !formik.values.isAgreed}
                >
                  {loading ? (
                    <Puff
                      height="25"
                      width="30"
                      radius="6"
                      color="white"
                      ariaLabel="loading"
                      wrapperStyle
                      wrapperClass
                    />
                  ) : (
                    "Create an account"
                  )}
                </Button>
              </BottomContainerStyled>
            </Form>
          )}
        </Formik>
        <br />
        <br />
      </LoginLayout>
    </>
  );
};

export default CustomerSignUp;

export const MyCheckBox = ({ label, error, ...rest }) => {
  return (
    <MyCheckBoxStyled>
      <label>
        <input type="checkbox" {...rest} />
        <p>{label}</p>
      </label>
      {error && <p>{error}</p>}
    </MyCheckBoxStyled>
  );
};

const BottomContainerStyled = styled.div`
  margin-top: 4rem;
  display: grid;
  gap: 2rem;
`;

const MyCheckBoxStyled = styled.div`
  display: grid;
  justify-content: center;
  & > label {
    display: grid;
    gap: 0.5rem;
    grid-template-columns: auto auto;
    justify-content: center;
    align-items: start;
    & > input {
      height: 2.4rem;
      display: inline-block;
    }
    & > p {
      font-size: 1.4rem;

      & > a {
        color: #ffc841;
        will-change: opacity;
        transition: opacity 0.25s ease-in;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }

  & > p {
    margin-left: 15px;
    color: red;
    font-size: 10px;
    font-weight: 400;
  }
`;
