export const BACKEND_URL = process.env.REACT_APP_ROOT;
export const BACKEND_STATIC_URL = process.env.REACT_APP_BACKEND_STATIC_URL;
export const WEEK_DAYS = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const NumberOfCaters = [
  "Up to 10 People",
  "10-30 People",
  "30-50 People",
  "Up to 100 People",
  "Up to 200 People",
  "Over 200 People",
  "Over 500 People",
];

export const BookingNoticePeriods = [
  "Less than a week",
  "1 Week",
  "2 Weeks",
  "3 Weeks",
  "4 Weeks",
  "4 weeks +",
];
