import React, { useEffect, useLayoutEffect } from "react";
import "./App.scss";
import Routess from "./Routes";

// Slider
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getLandingPageData,
  getAllOccasions,
  getAllServicesTypes,
  getAllTopOccasions2,
  getAllChats,
  createNewChatWithAdmins,
  updateMessages,
  getTotalNumberOfUnviewedServicesUpdate,
} from "./store/actions";
import { CircularProgress } from "@mui/material";
import { GlobalHook } from "./utils/globalHookData";
import { useUserChange } from "./hooks";
import { scrollToTop } from "./utils";
import { createSnapShotListenerForMessages } from "./firebase/message";
import useUser from "./hooks/useUser";
import { useLocation } from "react-router-dom";
import { createSnapShotListenerForNotifications } from "./firebase/notfication";
import { updateNotification } from "./store/actions/notification.action";
import { getUserDetails2 } from "./store/actions/user.action";
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter } from "react-router-dom";
import CookieConsent from "./components/CookieConsent/CookieConsent";

const AppWithoutProvider = () => {
  const { loading } = useSelector((s) => s.publicReducer);
  const { getUserDetails2Data } = useSelector((s) => s.userReducer);
  const loggedInUser = useUser();
  const { pathname } = useLocation();

  const dispatch = useDispatch();
  useLayoutEffect(() => {
    dispatch(getLandingPageData());
    dispatch(getAllOccasions());
    dispatch(getAllServicesTypes());
    getAllTopOccasions2({ defaultErrorHandler: false });
    getUserDetails2({ defaultErrorHandler: false });
  }, [dispatch]);

  useEffect(() => {
    if (loggedInUser) {
      createNewChatWithAdmins({
        onSuccess: () => {
          getAllChats();
        },
      });

      const unsubscribeMessage = createSnapShotListenerForMessages(
        loggedInUser?.id,
        ({ docs }) => {
          dispatch(
            updateMessages(docs.map((d) => ({ _id: d.id, ...d.data() })))
          );
        }
      );

      const unsubscribeNotifications = createSnapShotListenerForNotifications(
        loggedInUser?.id,
        ({ docs }) => {
          dispatch(
            updateNotification(docs.map((d) => ({ _id: d.id, ...d.data() })))
          );
        }
      );

      return () => {
        unsubscribeMessage();
        unsubscribeNotifications();
      };
    }
  }, [dispatch, loggedInUser]);

  useEffect(() => {
    scrollToTop();
  }, [pathname]);

  return (
    <>
      <GlobalHook />
      {getUserDetails2Data.loading || loading ? (
        <div className="loader">
          <CircularProgress />
        </div>
      ) : (
        <>
          <CookieConsent />
          <Routess />
        </>
      )}
    </>
  );
};

const App = () => {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <AppWithoutProvider />
      </Provider>
    </BrowserRouter>
  );
};

export default App;
