import "./AddCard.scss";
import { Container } from "../../../../components";
import { cardlogo, verified } from "../../../../assests";
import toast from "react-hot-toast";
import { AddPaymentCard } from "./../../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useState } from "react";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const AddCard = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.vendorReducer);

  // const validation = Yup.object().shape({
  //   name: Yup.string().required("Name is required."),
  //   number: Yup.string()
  //     .required("Card number is required.")
  //     .matches(/^\d{16}$/, "Please enter a valid 16-digit card number."),
  //   exp_month: Yup.string()
  //     .required("Expiry month is required.")
  //     .matches(
  //       /^(0[1-9]|1[0-2])$/,
  //       "Please enter a valid expiry month (01-12)."
  //     ),
  //   exp_year: Yup.string()
  //     .required("Expiry year is required.")
  //     .matches(/^\d{4}$/, "Please enter a valid 4-digit expiry year."),
  //   cvc: Yup.string()
  //     .required("CVC is required.")
  //     .matches(/^\d{3,4}$/, "Please enter a valid 3 or 4-digit CVC code."),
  // });

  const onTokenCreation = (id) => {
    dispatch(AddPaymentCard({ id }));
  };

  // useEffect(() => {
  //   if (errors?.length > 0) {
  //     toast.error(errors);
  //     dispatch(clearErrors());
  //   }
  //   if (message != "") {
  //     toast.success(message);
  //     dispatch(clearMessages());
  //     setTimeout(() => navigate("/vender-payments"), 2000);
  //   }
  // }, [errors, message]);

  return (
    <>
      <Container className="half">
        <div className="addcard  m-5">
          <div className="addcard-container-form">
            {/* <Formik
                initialValues={{
                  name: "",
                  number: "",
                  exp_month: "",
                  exp_year: "",
                  cvc: "",
                }}
                validateOnMount
                validationSchema={validation}
                onSubmit={async (values, { resetForm }) => {
                  const stripe = await loadStripe(
                    "pk_live_51NkVitEWitidVM7EwDzdbbIDbURMFHRWkwcsnqxLlGzVgRgbferLO0AYEFFRaXkDQADohlrcHc2SHGFuhEuNZNly00sULjeHSY"
                  );

                  try {
                    const token = await stripe.createToken("bank_account", {
                      number: values.number,
                      exp_month: values.exp_month,
                      exp_year: values.exp_year,
                      cvc: values.cvc,
                    });

                    console.log(token);
                  } catch (error) {
                    console.log(error);
                    toast.error("Something went wrong! Please try again.");
                  }

                  // dispatch(AddPaymentCard({ id: "" }));
                  // resetForm({ values: "" });
                }}
              >
                {(formik) => (
                  <Form>
                    <div className="align-item-center ">
                      <div className="addcardinput-heading">
                        <h3>Card Number</h3>
                        <p>Enter the 16-digit cardnumber on the card</p>
                      </div>
                      <img
                        src={verified}
                        alt="verified"
                        style={{ marginLeft: "4rem" }}
                      />
                    </div>
                    <div className="addcardinput-field-cardnumber m-1">
                      <img src={cardlogo} alt="card - logo" />
                      <div>
                        <FormInput
                          label=""
                          name="number"
                          type="text"
                          maxLength={16}
                        />
                      </div>
                    </div>
                    <div className="align-item-center m-3">
                      <div className="addcardinput-heading">
                        <h3>CVC Number</h3>
                        <p>Enter 3 digit cvc number</p>
                      </div>
                    </div>
                    <FormInput label="" name="cvc" type="text" />
                    <div className="card-input-flex">
                      <h3>Enter Card Name</h3>
                      <div>
                        <FormInput label="" name="name" type="text" />
                      </div>
                    </div>
                    <div className="card-input-flex">
                      <h3>Expiry Month</h3>
                      <div>
                        <FormInput label="" name="exp_month" type="text" />
                      </div>
                    </div>

                    <div className="card-input-flex">
                      <h3>Expiry Year</h3>
                      <div>
                        <FormInput label="" name="exp_year" type="text" />
                      </div>
                    </div>
                    <div className="cardform-button">
                      <button>{loading ? "Please Wait..." : "Add card"}</button>
                    </div>
                  </Form>
                )}
              </Formik> */}
            <Elements stripe={stripePromise}>
              <PaymentForm
                onTokenCreation={onTokenCreation}
                loading={loading}
              />
            </Elements>
          </div>
          {/* <div className="addcard-container-card">
              <div className="addcard-container-card-content">
                <div className="addcard-container-card-content-image">
                  <img src={card} alt="card-image" />
                </div>
                <div className="addcard-container-card-content-data">
                  <p>Shop name</p>
                  <p>Order Number</p>
                  <p>Event</p>
                  <p>Price</p>
                </div>

                <div className="addcard-container-card-content-pay center">
                  <p>
                    You have to pay <b>$200</b>
                  </p>
                </div>
              </div>
            </div> */}
        </div>
      </Container>
    </>
  );
};

export default AddCard;

const PaymentForm = ({ onTokenCreation, loading }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    if (!stripe || !elements) {
      toast.error("Stripe library hasn't loaded yet! Please try again.");
      setIsLoading(false);
      return;
    }

    const { error: _validation_error } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (_validation_error) {
      toast.error(_validation_error?.message);
      setIsLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);
    const { token, error } = await stripe.createToken(cardElement);
    console.log(CardElement);

    if (error) {
      console.log(error);
      toast.error(
        "Something went wrong with token creation. Please try again!"
      );
      setIsLoading(false);

      return;
    }
    setIsLoading(false);
    onTokenCreation(token.id);
    cardElement.clear();
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="align-item-center ">
        <div className="addcardinput-heading">
          <h3>Card Number</h3>
          <p>Enter the 16-digit cardnumber on the card</p>
        </div>
        <img src={verified} alt="verified" style={{ marginLeft: "4rem" }} />
      </div>
      <div className="addcardinput-field-cardnumber m-1">
        <img src={cardlogo} alt="card - logo" />
        <div>
          <CardElement options={{ hidePostalCode: true, disableLink: true }} />
        </div>
      </div>
      <div className="cardform-button">
        <button disabled={loading || isLoading} type="submit">
          {loading || isLoading ? "Please Wait..." : "Add card"}
        </button>
      </div>
    </form>
  );
};
