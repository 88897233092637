import { useState, useEffect, useCallback } from "react";
// import "./CreateService.scss";
import { useFormik } from "formik";
import Multiselect from "multiselect-react-dropdown";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import {
  GetSingleService,
  UpdateSingleService,
  clearErrors,
  clearMessages,
} from "./../../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { Container, Heading, Button } from "../../../../components";

// import { eventData } from "../../../../data/eventData";
import { RiCloseCircleLine } from "react-icons/ri";
import { BiTrash } from "react-icons/bi";
import { useGoogleLoc } from "../../../../hooks";
import uploadFilesFromObj from "../../../../utils/uploadFilesFromObject";
import {
  BACKEND_STATIC_URL,
  BookingNoticePeriods,
  NumberOfCaters,
  WEEK_DAYS,
} from "../../../../constants";
import { vendorServiceSchema } from "../../../../schema";
import { fileToBase64, generateRandomId, scrollToTop } from "../../../../utils";
import { Puff } from "react-loader-spinner";

const CreateService = () => {
  const { occasions } = useSelector((s) => s.occasionReducer);
  const { servicesTypes } = useSelector((s) => s.servicesTypesReducer);
  const [imageFileObjs, setImageFileObjs] = useState([]);
  const [images, setImages] = useState([]);
  const [serviceArea, setServiceArea] = useState("");

  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    errors: requestErrors,
    message,
    loading,
    subscriptionLoading,
  } = useSelector((state) => state.vendorReducer);

  useEffect(() => {
    if (requestErrors?.length > 0) {
      toast.error(requestErrors);
      dispatch(clearErrors());
    }
    if (message !== "") {
      toast.success(message);
      dispatch(clearMessages());
      setTimeout(() => navigate("/my-services"), 2000);
    }
  }, [requestErrors, message, dispatch, navigate]);

  const {
    values,
    setFieldValue,
    touched,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    initialValues: {
      serviceName: "",
      customServiceName: "",
      occassion: [],
      numberOfCaters: "",
      serviceArea: [],
      bookingWeekDays: [],
      bookingPeriod: "",
      description: "",
      thumbnails: [],
      referalLink: "",
      isProvidingServiceAtAllLoc: false,
    },

    validationSchema: vendorServiceSchema,

    onSubmit: (values, { resetForm }) => {
      const copy = {
        ...values,
        serviceName: values.serviceName[0]?._id || values.serviceName[0]?.id,
        occassion: values.occassion.map((o) => o._id),
      };

      if (copy.referalLink.trim() === "https://www.") {
        copy.referalLink = "No Provided";
      }

      if (copy.isProvidingServiceAtAllLoc) {
        copy.serviceArea = [];
      }

      uploadFilesFromObj(copy, ["png", "jpg", "jpeg"])
        .then((payload) => {
          dispatch(
            UpdateSingleService(payload, id, () => {
              scrollToTop();
              navigate("/my-services");
            })
          );
        })
        .catch(() => {});
    },
  });

  const onImageChange = useCallback((e) => {
    const files = Array.from(e.target.files);
    if (files.length === 0) return;
    const isLess = files.every((f) => f.size <= 5242880);
    if (!isLess) {
      toast.error("File size must be less than 5MB.");
      e.target.value = "";

      return;
    }
    const fileObjs = files.map((file) => ({
      file,
      isMain: false,
      id: generateRandomId(),
    }));
    setImageFileObjs((pre) => {
      const isMainAlreadySelected = pre.find((obj) => obj.isMain);
      if (isMainAlreadySelected) return [...pre, ...fileObjs];
      else {
        fileObjs[0].isMain = true;
        return [...pre, ...fileObjs];
      }
    });
    e.target.value = "";
  }, []);

  const onMainThumbnailChange = useCallback((id) => {
    setImageFileObjs((pre) =>
      pre.map((obj) => ({ ...obj, isMain: obj.id === id }))
    );
  }, []);

  const onImageRemove = useCallback((id) => {
    setImageFileObjs((pre) => {
      const afterRemoved = pre.filter((obj) => obj.id !== id);
      if (!afterRemoved.find((obj) => obj.isMain) && afterRemoved[0]) {
        afterRemoved[0].isMain = true;
      }
      return [...afterRemoved];
    });
  }, []);

  const inputRef = useGoogleLoc((place) => {
    setFieldValue(
      "serviceArea",
      Array.from(new Set([...values.serviceArea, place.formatted_address]))
    );
    setServiceArea("");
  });

  const removeService = useCallback(
    (area) => {
      setFieldValue(
        "serviceArea",
        values.serviceArea.filter((f) => f !== area)
      );
    },
    [setFieldValue, values.serviceArea]
  );

  useEffect(() => {
    dispatch(
      GetSingleService(id, (data) => {
        setFieldValue("serviceName", [data?.serviceName]);
        setFieldValue("occassion", data?.occassion);
        setFieldValue("numberOfCaters", data?.numberOfCaters);
        setFieldValue("serviceArea", data?.serviceArea);
        setFieldValue("bookingWeekDays", data?.bookingWeekDays);
        setFieldValue("bookingPeriod", data?.bookingPeriod);
        setFieldValue("description", data?.description);
        // setFieldValue("thumbnails", data?.thumbnails);

        const imgsObjs = data?.thumbnails?.map((link) => ({
          file: link,
          isMain: false,
          id: generateRandomId(),
        }));

        if (imgsObjs && imgsObjs[0]) {
          imgsObjs[0].isMain = true;
        }
        setImageFileObjs(imgsObjs || []);

        setFieldValue("customServiceName", data?.customServiceName);
        setFieldValue(
          "isProvidingServiceAtAllLoc",
          data?.isProvidingServiceAtAllLoc
        );
        setFieldValue(
          "referalLink",
          data?.referalLink
            ? data?.referalLink === "No Provided"
              ? "https://www."
              : data?.referalLink
            : ""
        );
      })
    );
  }, [dispatch, id, setFieldValue, servicesTypes]);

  useEffect(() => {
    const f1 = async () => {
      let images = await Promise.all(
        imageFileObjs.map((obj) =>
          typeof obj.file === "string"
            ? `${BACKEND_STATIC_URL}${obj.file}`
            : fileToBase64(obj.file)
        )
      );
      images = images.map((image, index) => ({
        ...imageFileObjs[index],
        image,
      }));
      setImages(images);
    };

    f1();
  }, [imageFileObjs]);

  useEffect(() => {
    if (imageFileObjs.length === 0) {
      setFieldValue("thumbnails", []);
    } else
      setFieldValue(
        "thumbnails",
        [
          imageFileObjs.find((obj) => obj.isMain),
          ...imageFileObjs.filter((obj) => !obj.isMain),
        ].map((obj) => obj.file)
      );
  }, [imageFileObjs, setFieldValue]);

  return (
    <>
      {loading ? (
        <Puff
          height="60"
          width="60"
          radius="6"
          color="black"
          ariaLabel="loading"
          wrapperStyle
          wrapperClass
        />
      ) : (
        <Container className="half">
          <div className="createservice">
            <Heading className="primary center m-5">Edit Service</Heading>
          </div>
          <Container className="md">
            <form onSubmit={handleSubmit}>
              <div className="selector-form-item-container">
                <label>Select Occasions</label>
                <br />
                <br />
                <div className="selector-form-item-row">
                  <Multiselect
                    className="multi-select-input-32f24 multiple"
                    onRemove={(sl) => {
                      setFieldValue("occassion", sl);
                    }}
                    onSelect={(sl, sI) => {
                      setFieldValue("occassion", sl);
                    }}
                    selectedValues={values.occassion}
                    options={occasions}
                    placeholder="Select Occasions"
                    displayValue="occasionName"
                  />

                  <button
                    type="button"
                    onClick={() => setFieldValue("occassion", occasions)}
                  >
                    Add All
                  </button>
                </div>
                {touched.occassion && errors.occassion && (
                  <p className="error">{errors.occassion}</p>
                )}
              </div>
              <br />
              <br />
              <div className="selector-form-item-container">
                <label>Select Service or Product</label>
                <br />
                <br />
                <div className="selector-form-item">
                  <Multiselect
                    className="multi-select-input-32f24 multiple"
                    onRemove={(sl) => {
                      setFieldValue("serviceName", sl);
                    }}
                    onSelect={(sl, sI) => {
                      setFieldValue("serviceName", sl);
                    }}
                    selectedValues={values.serviceName}
                    options={servicesTypes}
                    singleSelect
                    placeholder="Select Service or Product"
                    displayValue="serviceName"
                  />
                </div>
                {touched.serviceName && errors.serviceName && (
                  <p className="error">{errors.serviceName}</p>
                )}
              </div>
              <br />
              <br />
              <div className="selector-form-item-container">
                <label>Enter Service Title</label>
                <br />
                <br />
                <div className="selector-form-item server-area-af2lj2g">
                  <input
                    ref={inputRef}
                    className="input-afl2jf2j"
                    type="text"
                    placeholder="Enter Service Title"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.customServiceName}
                    name="customServiceName"
                  />
                </div>
                {touched.customServiceName && errors.customServiceName && (
                  <p className="error">{errors.customServiceName}</p>
                )}
              </div>
              <br />
              <br />
              <div className="selector-form-item-container">
                <label>Select Days</label>
                <br />
                <br />
                <div className="selector-form-item-row">
                  <Multiselect
                    className="multi-select-input-32f24 multiple"
                    isObject={false}
                    onRemove={(sl) => {
                      setFieldValue("bookingWeekDays", sl);
                    }}
                    onSelect={(sl, sI) => {
                      setFieldValue("bookingWeekDays", sl);
                    }}
                    selectedValues={values.bookingWeekDays}
                    options={WEEK_DAYS}
                    placeholder="Select Days"
                    displayValue="name"
                  />

                  <button
                    type="button"
                    onClick={() => setFieldValue("bookingWeekDays", WEEK_DAYS)}
                  >
                    Add All
                  </button>
                </div>
                {touched.bookingWeekDays && errors.bookingWeekDays && (
                  <p className="error">{errors.bookingWeekDays}</p>
                )}
              </div>
              <br />
              <br />
              <div className="selector-form-item-container">
                <label>Enter guest limit</label>
                <br />
                <br />
                <div className="selector-form-item">
                  <Multiselect
                    className="multi-select-input-32f24 only-one"
                    singleSelect
                    isObject={false}
                    onRemove={(sl, sI) => {
                      setFieldValue("numberOfCaters", sI);
                    }}
                    onSelect={(sl, sI) => {
                      setFieldValue("numberOfCaters", sI);
                    }}
                    selectedValues={[values.numberOfCaters]}
                    options={NumberOfCaters}
                    placeholder="Enter guest limit"
                  />
                </div>
                {touched.numberOfCaters && errors.numberOfCaters && (
                  <p className="error">{errors.numberOfCaters}</p>
                )}
              </div>
              <br />
              <br />
              <div className="selector-form-item-container">
                <label>Enter booking notice period</label>
                <br />
                <br />
                <div className="selector-form-item">
                  <Multiselect
                    className="multi-select-input-32f24 only-one"
                    singleSelect
                    isObject={false}
                    onRemove={(sl, sI) => {
                      setFieldValue("bookingPeriod", sI);
                    }}
                    onSelect={(sl, sI) => {
                      setFieldValue("bookingPeriod", sI);
                    }}
                    selectedValues={[values.bookingPeriod]}
                    options={BookingNoticePeriods}
                    placeholder="Enter booking notice period"
                  />
                </div>
                {touched.bookingPeriod && errors.bookingPeriod && (
                  <p className="error">{errors.bookingPeriod}</p>
                )}
              </div>
              <br />
              <br />
              <div className="selector-form-item server-area-232g2">
                <label>
                  <input
                    type="radio"
                    name="isProvidingServiceAtAllLoc"
                    onClick={() =>
                      setFieldValue("isProvidingServiceAtAllLoc", true)
                    }
                    checked={values.isProvidingServiceAtAllLoc}
                  />
                  <span>All Across UK</span>
                </label>
                <label>
                  <input
                    type="radio"
                    name="isProvidingServiceAtAllLoc"
                    onClick={() =>
                      setFieldValue("isProvidingServiceAtAllLoc", false)
                    }
                    checked={!values.isProvidingServiceAtAllLoc}
                  />
                  <span>Selected Locations</span>
                </label>
              </div>
              <br />
              <br />
              {!values.isProvidingServiceAtAllLoc && (
                <>
                  <div className="selector-form-item-container">
                    <label>Enter Service Area</label>
                    <br />
                    <br />
                    <div className="selector-form-item server-area-af2lj2g">
                      <input
                        ref={inputRef}
                        className="input-afl2jf2j"
                        type="text"
                        placeholder="Enter service area"
                        onChange={(e) => setServiceArea(e.target.value)}
                        value={serviceArea}
                      />
                      <div className="group-2f2t">
                        {values.serviceArea.map((area, i) => (
                          <p key={i}>
                            <span>{area}</span>
                            <button onClick={() => removeService(area)}>
                              <RiCloseCircleLine />
                            </button>
                          </p>
                        ))}
                      </div>
                    </div>
                    {touched.serviceArea && errors.serviceArea && (
                      <p className="error">{errors.serviceArea}</p>
                    )}
                  </div>
                  <br />
                  <br />
                </>
              )}
              <div className="selector-form-item-container">
                <label>Enter about service: </label>
                <br />
                <br />
                <div className="selector-form-item server-area-af2lj2g">
                  <textarea
                    rows={10}
                    placeholder="Please enter a descriptive summary of your service or product"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.description}
                    name="description"
                    className="input-afl2jf2j"
                  />
                </div>
                {touched.description && errors.description && (
                  <p className="error">{errors.description}</p>
                )}
              </div>
              <br />
              <br />
              <div className="selector-form-item-container">
                <label>Upload images</label>
                <br />
                <br />
                <div className="selector-form-item">
                  <div className="image-selector-custom">
                    <div className="images-group">
                      {images.map((obj, i) => (
                        <div
                          key={i}
                          onClick={() => onMainThumbnailChange(obj.id)}
                        >
                          <img
                            src={obj.image}
                            alt=""
                            className={obj.isMain && "selected"}
                          />

                          <button
                            type="button"
                            onClick={(e) => {
                              e.stopPropagation();
                              onImageRemove(obj.id);
                            }}
                          >
                            <BiTrash />
                          </button>
                        </div>
                      ))}
                    </div>
                    <label className="imageSelector">
                      <span>Upload Image</span>
                      <input
                        type="file"
                        accept=".png, .jpeg, .jpg"
                        multiple
                        onChange={onImageChange}
                      />
                    </label>
                  </div>
                </div>
                {touched.thumbnails && errors.thumbnails && (
                  <p className="error">{errors.thumbnails}</p>
                )}
              </div>
              <br />
              <br />
              <div className="selector-form-item-container">
                <label>Website link:</label>
                <br />
                <br />
                <div className="selector-form-item server-area-af2lj2g">
                  <input
                    className="input-afl2jf2j"
                    type="text"
                    placeholder="Website link"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.referalLink}
                    name="referalLink"
                  />
                </div>
                {touched.referalLink && errors.referalLink && (
                  <p className="error">{errors.referalLink}</p>
                )}
              </div>
              <Button className="btn-lighter rounded center m-2">
                {subscriptionLoading ? "Please wait" : "Update service"}
              </Button>
            </form>
          </Container>
        </Container>
      )}
    </>
  );
};

export default CreateService;
