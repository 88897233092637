import { useEffect } from "react";
import { Card, Container, GoBackBtn, Selector } from "../../../components";
import { Puff } from "react-loader-spinner";
import { getAllVendorServices2 } from "./../../../store/actions";
import { useSelector } from "react-redux";
import useQueryParams from "../../../hooks/useQueryParams";
import Pagination from "../../../components/common/Pagination/Pagination";
import { BACKEND_STATIC_URL } from "../../../constants";
import styled from "@emotion/styled";

const SearchResult = () => {
  const { setQueryParam, occassion, service, city, page } = useQueryParams({
    occassion: "",
    city: "",
    service: "",
    page: "1",
  });

  const { servicesTypes } = useSelector((s) => s.servicesTypesReducer);
  const { getAllVendorServicesData } = useSelector(
    (s) => s.vendorServices2Reducer
  );

  useEffect(() => {
    getAllVendorServices2({
      payload: {
        filters: {
          occassion,
          serviceArea: city,
          serviceName: service,
          limit: 10,
          page: Number(page),
          status: "Accepted",
          deleteStatus: "NULL",
        },
      },
    });
  }, [occassion, city, service, page]);

  return (
    <>
      <SearchPageStyled className="searchpage">
        <Container className="main">
          <Container className="lg">
            <GoBackBtn />

            <div className="space-between">
              <div>
                <h4 style={{ fontSize: "1.4rem", fontWeight: "500" }}>
                  {/* 200 Search */}
                </h4>
              </div>
              <div className="align-item-right">
                <div className="search-service-selector">
                  <Selector
                    selected={
                      servicesTypes?.find((o) => o?._id === service)?.name ||
                      "Choose Service"
                    }
                    setSelected={(v) => {
                      setQueryParam({ service: v._id });
                    }}
                    data={servicesTypes}
                    className="selector-rounded"
                    fullObj
                  />
                </div>
              </div>
            </div>
            <SearchGrid>
              {getAllVendorServicesData.loading ? (
                <Puff
                  height="60"
                  width="60"
                  radius="6"
                  color="black"
                  ariaLabel="loading"
                  wrapperStyle
                  wrapperClass
                />
              ) : getAllVendorServicesData?.results?.length > 0 ? (
                getAllVendorServicesData?.results?.map((data, ind) => {
                  return (
                    <Card
                      key={ind}
                      image={`${BACKEND_STATIC_URL}${data.thumbnails[0]}`}
                      name={
                        // heading === "occassion" ? data?.occassion : data?.name
                        data?.customServiceName ||
                        data?.serviceName?.serviceName ||
                        "--"
                      }
                      description={data?.description || "--"}
                      likes={data?.like?.length}
                      views={data?.views?.length}
                      id={data.id}
                      verified={data?.vendor?.verfied}
                    />
                  );
                })
              ) : (
                <h1>No record found</h1>
              )}
            </SearchGrid>
          </Container>
        </Container>

        {getAllVendorServicesData?.totalPages > 1 && (
          <>
            <Pagination totalPages={getAllVendorServicesData?.totalPages} />
            <br />
            <br />
          </>
        )}
      </SearchPageStyled>
    </>
  );
};

export default SearchResult;

const SearchGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2.5rem;

  padding: 2.5rem 0;

  /* @media screen and (max-width: 1800px) {
      grid-template-columns: repeat(5, 1fr);
    } */

  @media screen and (max-width: 1300px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (max-width: 950px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: 700px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const SearchPageStyled = styled.div`
  .container-lg {
    margin: 0 auto;
  }
`;
