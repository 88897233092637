import styled from "@emotion/styled";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

const CookieConsentStyled = styled.div`
  box-sizing: border-box;
  background-color: white;
  padding: 1.6rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.8rem;
  align-content: start;
  position: fixed;
  z-index: 100000000000000;
  left: 1.6rem;
  bottom: 1.6rem;
  width: calc(100% - 3.2rem);
  max-width: 40rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 1.2rem;
  -webkit-box-shadow: 10px 10px 17px -10px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 17px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 17px -10px rgba(0, 0, 0, 0.75);

  & > h2 {
    font-size: 2rem;
    color: rgba(0, 0, 0, 0.7);
  }

  & > div {
    display: grid;
    gap: 1.2rem;
    align-content: start;

    & > p {
      font-size: 1.4rem;
      color: rgba(0, 0, 0, 0.5);
    }

    & > div {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 0.8rem;

      @media screen and (max-width: 500px) {
        grid-template-columns: 1fr;
      }

      & > button,
      & > a {
        background-color: #ffc841;
        border: 1px solid #ffc841;
        border-radius: 0.8rem;
        outline: none;
        font-size: 1.4rem;
        padding: 0.8rem 1.2rem;
        display: grid;
        place-items: center;
        cursor: pointer;
        transition: background-color 0.3s ease-in-out;
        will-change: background-color;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.7);

        &:hover {
          background-color: transparent;
        }
      }
    }
  }
`;

const LOCAL_STORAGE_COOKIE_KEY = "is-cookie-accepted";

const CookieConsent = () => {
  const [cookieState, setCookieState] = useState(
    Number(localStorage.getItem(LOCAL_STORAGE_COOKIE_KEY) || "0")
  );
  const isScriptAdded = useRef(false);

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_COOKIE_KEY, String(cookieState));
  }, [cookieState]);

  useEffect(() => {
    if (cookieState !== 1 || isScriptAdded.current) return;
    isScriptAdded.current = true;
    const script1 = document.createElement("script");
    script1.async = true;
    script1.src = "https://www.googletagmanager.com/gtag/js?id=G-8RK30P11BJ";

    const script2 = document.createElement("script");
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", "G-8RK30P11BJ");
    `;
    document.body.append(script1);
    document.body.append(script2);
  }, [cookieState]);

  return (
    cookieState === 0 && (
      <CookieConsentStyled>
        <h2>Cookie Consent</h2>
        <div>
          <p>
            We serve cookies on this site to analyze traffic, remember your
            preferences, and optimize your experience.
          </p>

          <div>
            <button onClick={() => setCookieState(1)}>Accept</button>
            <button onClick={() => setCookieState(2)}>Reject</button>
            <Link to="/cookie-policy">More Details</Link>
          </div>
        </div>
      </CookieConsentStyled>
    )
  );
};

export default CookieConsent;
