import axios from "axios";
import { occasionConstant } from "../constants";
import { BACKEND_URL } from "../../constants";

export const getAllOccasions = () => {
  return async (dispatch) => {
    dispatch({
      type: occasionConstant.GET_ALL_OCCASIONS_REQUEST,
    });

    try {
      const res = await axios.get(`${BACKEND_URL}/v1/api/occasions`);

      if (res.status === 200) {
        const occasionList = res.data;
        // console.log(occasionList);
        dispatch({
          type: occasionConstant.GET_ALL_OCCASIONS_SUCCESS,
          payload: { occasions: occasionList },
        });
      } else {
        // console.log(res);
        console.log(res.data.error);
        dispatch({
          type: occasionConstant.GET_ALL_OCCASIONS_FAILURE,
          payload: { error: res.data.error },
        });
      }
    } catch (error) {
      dispatch({
        type: occasionConstant.GET_ALL_OCCASIONS_FAILURE,
        payload: {
          error:
            error?.res?.data?.error ||
            error?.res?.data?.message ||
            error?.message,
        },
      });
    }
  };
};
